<template>
  <div class="text-black flex">
    <Sidebar />
    <div class="font-semibold flex-1 h-screen">
      <Navbar title="Profile" />
      <div id="profile-content" class="p-7">
        <div class="profile-details pt-8 md:pt-0" v-if="profile">
          <div
            class="bg-nColorFillGray border border-nColorStrokeGray rounded-xl w-full mt-2"
            v-if="profile"
          >
            <div class="sm:flex m-2 items-start relative">
              <div
                class="absolute top-2 md:top-4 right-0 md:right-4 bg-green-600 px-2 md:px-4
                   py-0.5 md:py-1 text-sm md:text-base font-semibold"
                v-if="profile.status == 1"
              >
                Active
              </div>
              <div
                class="absolute top-2 md:top-4 right-0 md:right-4 bg-red-600 px-4 py-1 font-semibold"
                v-else
              >
                Inactive
              </div>
              <div
                class="py-5 px-5 mb-4 md:mr-8 flex items-start border-b md:border-none border-gray-200"
              >
                <img
                  class="rounded-full w-20 md:w-48 shadow-lg border inline-block"
                  :src="profile.logo.square"
                  alt="company logo"
                />
                <div class="flex flex-col ml-4 md:hidden">
                  <h1 class="inline-block text-gray-700 text-xl font-bold">
                    {{ profile.company_name }}
                  </h1>
                  <div
                    class="text-gray-700 text-sm tracking-wide mt-2 md:mb-2 md:max-w-lg"
                    v-if="profile.member_since"
                  >
                    <span class="text-gray-500">Member since</span>
                    <span class="block font-semibold">{{
                      profile.member_since
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="py-5 px-5">
                <h1
                  class="hidden md:block text-gray-700 text-xl md:text-4xl font-bold mb-4"
                >
                  {{ profile.company_name }}
                </h1>
                <div class="flex flex-col md:flex-row gap-2 md:gap-20">
                  <div class="flex md:flex-col gap-8 md:gap-0">
                    <p
                      class="hidden md:block text-gray-700 text-base tracking-wide mb-2 md:max-w-lg"
                      v-if="profile.member_since"
                    >
                      <span class="text-gray-500">Member since</span>
                      <span class="block font-semibold">{{
                        profile.member_since
                      }}</span>
                    </p>
                    <p
                      class="w-1/2 md:w-full text-gray-700 text-base tracking-wide mb-2 md:max-w-lg"
                      v-if="profile.no_of_employees"
                    >
                      <span class="text-gray-500">Team size</span>
                      <span class="block font-semibold">{{
                        profile.no_of_employees
                      }}</span>
                    </p>
                    <p
                      class="w-1/2 md:w-full text-gray-700 text-base tracking-wide mb-2 md:max-w-lg"
                      v-if="profile.no_of_years"
                    >
                      <span class="text-gray-500">Years in business</span>
                      <span class="block font-semibold">{{
                        profile.no_of_years
                      }}</span>
                    </p>
                  </div>
                  <div class="flex md:flex-col gap-8 md:gap-0">
                    <p
                      class="w-1/2 md:w-full text-gray-700 text-base tracking-wide mb-2 md:max-w-lg"
                    >
                      <span class="text-gray-500">Revenue</span>
                      <span class="block font-semibold">
                        <span v-if="profile.revenue">
                          {{ profile.revenue }} USD
                        </span>
                        <span v-else>
                          -
                        </span>
                      </span>
                    </p>
                    <p
                      class="w-1/2 md:w-full text-gray-700 text-base tracking-wide mb-2 md:max-w-lg"
                      v-if="profile.domains"
                    >
                      <span class="text-gray-500">Domains</span>
                      <span class="block font-semibold">
                        {{ profile.domains }}
                      </span>
                    </p>
                  </div>
                  <div class="flex md:flex-col gap-8 md:gap-0">
                    <p
                      class="w-1/2 md:w-full text-gray-700 text-base tracking-wide mb-2 md:max-w-lg"
                      v-if="profile.ways_of_working"
                    >
                      <span class="text-gray-500">Work options</span>
                      <span class="block font-semibold">
                        <ul class="list-disc list-inside font-normal">
                          <li v-for="way in profile.ways_of_working" :key="way">
                            {{ way }}
                          </li>
                        </ul>
                      </span>
                    </p>
                    <p
                      class="w-1/2 md:w-full text-gray-700 text-base tracking-wide mb-2 md:max-w-lg"
                      v-if="profile.locations"
                    >
                      <span class="text-gray-500">Locations</span>
                      <span class="block font-semibold">
                        <ul class="list-disc list-inside font-normal">
                          <li v-for="loc in profile.locations" :key="loc">
                            {{ loc }}
                          </li>
                        </ul>
                      </span>
                    </p>
                  </div>
                  <div class="flex md:flex-col gap-8 md:gap-0">
                    <p
                      class="w-1/2 md:w-full text-gray-700 text-base tracking-wide mb-2 md:max-w-lg"
                      v-if="profile.website"
                    >
                      <span class="text-gray-500">Website</span>
                      <span class="block font-semibold">
                        <a :href="profile.website" target="_blank">
                          {{ profile.website }}</a
                        >
                      </span>
                    </p>
                    <p
                      class="w-1/2 md:w-full text-gray-700 text-base tracking-wide mb-2 md:max-w-lg"
                      v-if="profile.email"
                    >
                      <span class="text-gray-500">Email</span>
                      <span class="block font-semibold">
                        {{ profile.email }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col md:flex-row gap-2 md:gap-4 mt-2 md:mt-4">
            <div
              class="w-full px-6 py-4 bg-nColorFillGray border border-nColorStrokeGray rounded-xl"
              v-if="profile.services"
            >
              <h2 class="text-gray-800 text-xl font-semibold">
                Services
              </h2>
              <span class="text-gray-600 text-base">
                <ul class="list-disc list-inside font-normal two-col-ul mt-2">
                  <li v-for="service in profile.services" :key="service">
                    {{ service }}
                  </li>
                </ul>
              </span>
            </div>
            <div
              class="w-full px-6 py-4 bg-nColorFillGray border border-nColorStrokeGray rounded-xl"
              v-if="profile.employee_benefits"
            >
              <h2 class="text-gray-800 text-xl font-semibold">
                Employee Benefits
              </h2>
              <span class="text-gray-600 text-base">
                <ul class="list-disc list-inside font-normal two-col-ul mt-2">
                  <li
                    v-for="benefit in profile.employee_benefits"
                    :key="benefit"
                  >
                    {{ benefit }}
                  </li>
                </ul>
              </span>
            </div>
            <div
              class="w-full px-6 py-4 bg-nColorFillGray border border-nColorStrokeGray rounded-xl mb-2 md:mb-0"
              v-if="profile.values"
            >
              <h2 class="text-gray-800 text-xl font-semibold">
                Values
              </h2>
              <span class="text-gray-600 text-base">
                <ul class="list-disc list-inside font-normal two-col-ul mt-2">
                  <li v-for="value in profile.values" :key="value">
                    {{ value }}
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Sidebar from "@/components/Common/Sidebar/Standard";
import Navbar from "@/components/Common/Navbar/Standard";

export default {
  name: "Profile",
  components: { Sidebar, Navbar },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters(["profile"]),
    locationString() {
      if (this.profile.locations) {
        return this.profile.locations.join(", ");
      }
      return "";
    },
  },
  methods: {
    getHeaderText() {
      return this.profile.company_name + " Profile";
    },
  },
};
</script>

<style scoped lang="postcss">
.two-col-ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
